/**
 * @module mw-ui-themes/ThemeEnum
 */
define([], function () {
    /**
     * @member ThemeEnum
     * @description Enum for theme names
     * @readonly
     * @static
     * @property {String} LIGHT - Light theme
     * @property {String} DARK - Dark theme
     * @example ThemeEnum.LIGHT
     */
    const KEYS = Object.freeze({
        LIGHT: 'light',
        DARK: 'dark'
    });

    return KEYS;
});
